import React, { useEffect, useRef, useState } from 'react';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import styles from '../CheckoutForms.module.scss';
import { useCountriesStates } from '../../../shared/hooks/countries';

const ShippingInformation = ({ handleDoneClick }) => {
  return (
    <div className={styles.sectionWrapper}>
      <div className={`${styles.field}`}>
        <TextInput
          name={'shippingAddress'}
          placeholder="Address 1&#42;"
          onKeyDown={handleDoneClick}
        />
      </div>
    </div>
  );
};

ShippingInformation.defaultProps = {
  removeTitle: false,
  removeEmail: false,
  fieldsNames: {
    firstName: 'firstName',
    lastName: 'lastName',
    addressName1: 'line1',
    addressName2: 'line2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
    country: 'country',
    email: 'receiptEmail',
  },
};

export default ShippingInformation;
